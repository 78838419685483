import React, { useState } from "react";
import { Link } from "gatsby";

export default function Product({
  slug,
  name,
  description,
  image,
  addToCart,
  variants,
}) {
  const [showBtn, setShowBtn] = useState(false);

  const addingToCart = (id, qty) => {
    addToCart(id, qty);
  };
  return (
    <div
      onMouseEnter={() => setShowBtn(true)}
      onMouseLeave={() => setShowBtn(false)}
    >
      <div className="flex flex-col items-center justify-center">
        <Link to={slug}>
          <div>
            <img src={image} alt={name} height={285} width={285} />
          </div>
        </Link>
        <div className="flex flex-col items-center justify-center mt-[19px]">
          <Link to={slug}>
            <p className="text-[15px] leading-[177.4%] text-black">{name}</p>
          </Link>
          <span className="h-[1px] bg-black block mt-[6px] w-[77px]"></span>
        </div>
        <div className="mt-[6px]">
          {!showBtn ? (
            <p className="text-xs leading-[130%] text-center max-w-[230px] min-h-[32px] max-h-8 overflow-hidden">
              {description}
            </p>
          ) : (
            <div>
              <button
                className="flex items-center justify-center"
                onClick={() => addingToCart(variants[0]?.node?.id, "1")}
              >
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.50133 4.17692C9.12248 3.73297 8.55139 3.475 7.75978 3.38501V2.92906C7.75978 2.10716 7.43182 1.31525 6.85508 0.763316C6.27268 0.199382 5.51499 -0.0645874 4.72903 0.0134035C3.37764 0.151387 2.24111 1.53723 2.24111 3.03705V3.38501C1.4495 3.475 0.878405 3.73297 0.499562 4.17692C-0.0489128 4.82484 -0.0319496 5.68874 0.0302485 6.28867L0.426055 9.63028C0.544797 10.8001 0.991492 12 3.42287 12H6.57801C9.00939 12 9.45609 10.8001 9.57483 9.63628L9.97064 6.28267C10.0328 5.68874 10.0441 4.82484 9.50133 4.17692ZM4.80819 0.847306C5.37363 0.793312 5.9108 0.97929 6.32922 1.38124C6.74199 1.7772 6.97382 2.34113 6.97382 2.92906V3.34901H3.02707V3.03705C3.02707 1.96917 3.85826 0.943295 4.80819 0.847306ZM2.97618 6.69062H2.97052C2.65953 6.69062 2.40509 6.42065 2.40509 6.09069C2.40509 5.76073 2.65953 5.49076 2.97052 5.49076C3.28717 5.49076 3.54162 5.76073 3.54162 6.09069C3.54162 6.42065 3.28717 6.69062 2.97618 6.69062ZM6.93424 6.69062H6.92859C6.61759 6.69062 6.36315 6.42065 6.36315 6.09069C6.36315 5.76073 6.61759 5.49076 6.92859 5.49076C7.24523 5.49076 7.49968 5.76073 7.49968 6.09069C7.49968 6.42065 7.24523 6.69062 6.93424 6.69062Z"
                    fill="black"
                  />
                </svg>
                <span className="text-xs leading-[130%] ml-[2px] font-primary">
                  Add to Cart
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
