import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getShopifyImage } from "gatsby-source-shopify";
import { navigate } from "gatsby";

export default function CartPopup({ list, onClick }) {
  const goCartPage = () => {
    navigate("/cart");
    onClick();
  };
  return (
    <div
      className="fixed top-0 bottom-0 left-0 right-0 z-50 bg-black/60"
      onClick={onClick}
    >
      <div className="flex justify-end">
        <div className="bg-white p-5 min-w-[350px] mx-5 mt-[80px] mb-5 overflow-y-auto">
          <div className="mb-5 overflow-x-auto max-h-72">
            {list.map(({ node }, index) => {
              return <Items key={index} item={node} />;
            })}
          </div>

          <button
            className="py-[10px] px-14 bg-[#2C2C2C] text-white text-md w-full font-medium tracking-[0.21em]"
            onClick={goCartPage}
          >
            Go To Cart
          </button>
        </div>
      </div>
    </div>
  );
}

const Items = ({ item }) => {
  const variantImage = {
    // ...item.variant.image,
    src: item.merchandise.image.url,
    originalSrc: item.merchandise.image.url,
  };

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 59,
        height: 79,
      }),
    [variantImage.src]
  );

  return (
    <div className="flex py-2">
      {image && (
        <div>
          <GatsbyImage image={image} alt="pro" />
        </div>
      )}
      <div className="mt-1 ml-2 ">
        <p className="font-primary font-medium text-xs text-black leading-[177.4%]">
          {item?.merchandise?.product?.title}{" "}
          {item.merchandise?.title !== "Default Title" &&
            `- ${item?.merchandise?.title}`}
        </p>
        <p className="font-primary font-medium text-xs text-black leading-[177.4%]">
          X {item.quantity}
        </p>
      </div>
    </div>
  );
};
