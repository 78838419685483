import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  nextArrow: <NextArrow />,
  prevArrow: <PreArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: false,
        verticalSwiping: false,
      },
    },
  ],
};
export default function Gallery({ images, setImage }) {
  if (images.length > 3)
    return (
      <div className="xl:max-w-[92px] max-w-[320px] sm:max-w-[600px] lg:max-w-[405px] px-6 xl:px-0 relative mt-8 xl:mt-0 max-h-[689px] overflow-y-hidden overflow-x-hidden">
        <div>
          <Slider {...settings} className="w-full">
            {images.map((item, index) => (
              <div
                className="px-2 xl:pb-[14px] cursor-pointer outline-none"
                onClick={() => setImage(item?.preview?.image?.gatsbyImageData)}
                key={index}
              >
                <div className="xl:w-[92px] xl:h-[120px] w-full h-full">
                  <GatsbyImage
                    image={item?.preview?.image?.gatsbyImageData}
                    objectFit="cover"
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  else
    return (
      <div className="xl:max-w-[92px] xl:min-w-[100px] max-w-[500px] flex flex-row xl:flex-col w-full overflow-x-auto ml-6 xl:ml-0 relative mt-8 xl:mt-0">
        {images.map((item, index) => (
          <div
            className="px-2 xl:pb-[14px] max-w-[92px] xl:mr-2 cursor-pointer outline-none"
            key={index}
            onClick={() => setImage(item?.preview?.image?.gatsbyImageData)}
          >
            <div className="xl:w-[92px] xl:h-[125px] w-full h-full">
              <GatsbyImage image={item?.preview?.image?.gatsbyImageData} objectFit="cover" />
            </div>
          </div>
        ))}
      </div>
    );
}

function PreArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow z-10 h-[50px] w-[30px] absolute top-[30%] sm:top-[40%] -left-8 cursor-pointer hover:bg-opacity-60 xl:h-0 xl:w-0"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-full h-full rotate-90 xl:hidden">
        <svg
          width="18"
          height="11"
          viewBox="0 0 18 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 1L9 9L0.999999 1"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="slick-arrow z-10 h-[50px] w-[30px] absolute top-[30%] sm:top-[40%] xl:top-auto xl:-bottom-8 xl:left-8 -right-6 xl:right-auto cursor-pointer hover:bg-opacity-60"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-full h-full -rotate-90 xl:rotate-0">
        <svg
          width="18"
          height="11"
          viewBox="0 0 18 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 1L9 9L0.999999 1"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}
