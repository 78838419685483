import React, { useEffect, useState } from "react";
import Product from "./product";
import { StoreContext } from "../../context/storeContext";
import { getRelatedProducts } from "../../api/query";

export default function RelatedProducts({ productId, merch }) {
  const { addVariantToCart } = React.useContext(StoreContext);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function getRecommendedProducts() {
      const response = await getRelatedProducts(productId);
      if (response) {
        const filteredProducts = response.filter((item) =>
          item.tags.includes(merch ? "merch" : "mix")
        );
        setProducts(filteredProducts);
      }
    }
    getRecommendedProducts();
  }, []);

  if (products?.length === 0) return <></>;
  return (
    <div className="mix-container">
      <div>
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-[25px] leading-common uppercase font-bold font-primary text-center sm:text-left">
            MORE {merch ? "MERCH" : "MIXERS"} YOU MIGHT LIKE
          </h2>
          <span className="h-[2px] bg-black block mt-[5px] w-[123px]"></span>
        </div>
        <p className="text-[15px] mt-[10px] font-primary leading-common max-w-[530px] text-center font-medium mx-auto px-4 md:px-0">
          {/* We are an upscale casual, social brand that enhances every party
      and takes your bar from bland to grand. */}
        </p>
      </div>

      <div className="mt-[82px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
        {products?.length > 0 &&
          products?.slice(0, 3).map((item, index) => {
            return (
              <div key={index}>
                <Product
                  description={item?.description}
                  name={item?.title}
                  image={item?.featuredImage?.url}
                  slug={`/${merch ? "merch" : "products"}/${item?.handle}`}
                  variants={item?.variants?.edges}
                  addToCart={addVariantToCart}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
